import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageProps {
  data: {
    page: {
      title?: string
      path?: string
      databaseId?: number
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
}

const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { databaseId, flexContent, seo },
  },
  location = {},
  prefix = 'page_Flexcontent',
}) => (
  <Layout id={databaseId}>
    <SEO seo={seo} />
    <FlexContentHandler
      prefix={prefix}
      fields={flexContent}
      location={location}
    />
  </Layout>
)

export const pageQuery = graphql`
  query pageById($pageId: String!) {
    page: wpPage(id: { eq: $pageId }) {
      ...generalPageFragment
    }
  }
`

export default PageTemplate
